export * from '@/api/mock_chart'

export const lorem = 'detalle de la expendedora'
export const expend1 = 'detalle de la expendedora'
export const expend2 = 'detalle de la expendedora'
export const expend3 = 'detalle de la expendedora'

export const expendedorasInfo = {
  DatoExpend1: 'Detalle de la expendedora 1.',
  DatoExpend2: 'Detalle de la expendedora 2.',
  DatoExpend3: 'Detalle de la expendedora 3.'

}

export const empresaInfo = {
  DatoEmpresa: 'FARINA EXPENDEDORAS es una empresa argentina, dedicada al diseño y fabricación de todo tipo de expendedoras. Representa una solución para los operadores de la región ya que ofrece máquinas de calidad internacional a precios de mercado local. Las máquinas de FARINA EXPENDEDORAS son diseñadas pensando en la robustez, la confiabilidad y la seguridad necesarias para su uso en el mercado latinoamericano. La utilización de insumos nacionales garantiza la provisión de repuestos y el Departamento de Soporte Técnico, un servicio post-venta qué sólo una empresa local puede brindar.',
  DatoContacto: 'Para contactarse con nosotros:'

}

export const landingInfo = {
  title: 'Panel de Control',
  email: 'consultas@farinavending.com',
  phone: '+54 223 474-9189',
  adres: 'Av. Libertad 5621 | B7600 | Mar del Plata | Argentina'
}

export const userAdmin = {
  email: 'norman',
  password: '142536',
  user: 'NelsonEAX',
  status: 'admin',
  code: '',
  token: '3b759a9ca80234563d87672350659b2b',
  name: 'Nikolaev Nikolay',
  avatar: 'https://avatars.githubusercontent.com/u/13101802',
  roles: ['admin', 'editor']
}

export const userEditor = {
  email: 'editor@vvadmin.io',
  password: 'abcd1234',
  user: 'Editor',
  status: '',
  code: '',
  token: '09d0a5b30b267c2504fadd43348fbba3',
  name: 'Editor Name',
  avatar: '',
  roles: ['editor']
}
