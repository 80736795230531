const items = [
  {
    idEstadoPago: 'Generado',
    Descripcion: 'Generado'
  },
  {
    idEstadoPago: 'Pagado',
    Descripcion: 'Pagado'
  },
  {
    idEstadoPago: 'Cancelado',
    Descripcion: 'Cancelado'
  },
  {
    idEstadoPago: 'ACancelar',
    Descripcion: 'ACancelar'
  }
]

export default {
  ListCombo () {
    return new Promise((resolve, reject) => {
      // Recupera del cache la list de items
      const storage = sessionStorage.getItem('EstadoPago_items')

      // Si ya estan los datos en el storage, se retorna de ahi, solo para las paramtétricas
      if (storage)
        resolve(JSON.parse(storage))
      else {
        // realizo el llamado ajax
        // const p = axios.get('/api/Nacionalidad/ListCombo', { params });
        const p = new Promise((resolve) => {
          resolve(items)
        })

        p.then((result) => {
          sessionStorage.setItem('EstadoPago_items', JSON.stringify(result))
          resolve(result)
        }).catch((err) => {
          reject(err)
        })
      }
    })
  }
}
