<script>
import casCombo from './casCombo.vue'

export default {
  name: 'cbPos',
  // Herencia de Componentes (Se hace un merge del padre con el hijo)
  // No hay composicion entonces se evita la sobrecarga de propiedades.
  extends: casCombo,
  props: {
    label: {
      type: String,
      default: 'POS'
    }
  },
  data () {
    return {
      itemText: 'NroSerie',
      itemValue: 'Id'
    }
  },
  computed: {
    itemslist () {
      return this.$store.state.Pos.itemsCombo
    }
  },
  mounted () {
    this.$store.dispatch('Pos/ListCombo') // change
  }
}
</script>
