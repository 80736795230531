import PosApi from '../../api/PosApi'

/* eslint no-param-reassign: ["error", { "props": false }] */
export default {
  namespaced: true,
  state: {
    showMQTTMessageDialog: false,
    showGuardar: true,
    showGuardando: false,
    ShowResponse: false,
    response: null
  },
  getters: {
    getShowGuardando (state) {
      return state.showGuardando
    },
    getShowGuardar (state) {
      return state.showGuardar
    },
    getShowResponse (state) {
      return state.ShowResponse
    }
  },
  actions: {
    Show ({ commit }) {
      commit('setShowMQTTMessageDialog', true)
    },
    Hide ({ commit }) {
      commit('setShowMQTTMessageDialog', false)
    },
    async SendMessage ({ commit, dispatch }, message) {
      try {
        commit('setGuardar', false)
        commit('setGuardando', true)
        commit('setResponse', null)
        const response = await PosApi.SendMQTTMessage(message)
        if (response.data.error)
          throw new Error(response.data.error)
        console.log(response.data.responseMsg)
        commit('setResponse', response.data.responseMsg)
        dispatch('ShowResponse', true)
        dispatch('Notify/changeShowNotify', {
          colorNotify: 'success',
          textNotify: 'Mensaje enviado correctamente'
        }, { root: true })
      } catch (error) {
        dispatch('Notify/changeShowNotify', {
          colorNotify: 'error',
          textNotify: `Error al enviar mensaje - ${JSON.stringify(error)}`
        }, { root: true })
      } finally {
        commit('setGuardar', true)
        commit('setGuardando', false)
      }
    },
    ShowResponse ({ commit }, response) {
      commit('setShowResponse', response)
    }
  },
  mutations: {
    setShowMQTTMessageDialog (state, value) {
      state.showMQTTMessageDialog = value
    },
    toggleMQTTMessageDialog (state) {
      state.showMQTTMessageDialog = !state.showMQTTMessageDialog
    },
    setGuardar (state, value) {
      state.showGuardar = value
    },
    setGuardando (state, value) {
      state.showGuardando = value
    },
    setShowResponse (state, value) {
      state.ShowResponse = value
    },
    setResponse (state, value) {
      state.response = value
    }
  }
}
