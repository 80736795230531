/* eslint-disable vue/require-default-prop */
/* eslint-disable vue/require-default-prop */
/* eslint-disable vue/prop-name-casing */
<template>
  <v-autocomplete
    ref="innerCb"
    :items="items"
    :value="valueFormatted"
    :disabled="Deshabilitado"
    :readonly="readonly"
    :clearable="clearable"
    :dense="dense"
    :solo="solo"
    :solo-inverted="soloInverted"
    :filled="filled"
    :dark="dark"
    :light="light"
    :item-text="itemText"
    :item-value="itemValue"
    :label="label"
    :rules="casRules"
    :hint="hint"
    persistent-hint
    :placeholder="placeholder"
    :chips="multiple"
    :multiple="multiple"
    :search-input.sync="search"
    :loading="isLoading"
    :hide-no-data="hideNoData"
    :hide-details="hideDetails"
    :flat="flat"
    :required="required"
    :prepend-icon="prependIcon"
    :append-icon="appendIcon"
    autocomplete="nope"
    @input="inputChange($event)"
  />
</template>

<!--
  @change="changeValue($event)" evento de tipo @change
  ejecutará la función changeValue cada vez que el componente emita un evento update.
-->

<script>
import casRuleable from './mixins/casRuleable'

export default {
  name: 'CasCombo',
  components: {},
  mixins: [casRuleable],
  props: {
    label: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      default: null
    },
    disabled: Boolean,
    readonly: Boolean,
    opcionTodos: Boolean,
    dense: {
      type: Boolean
    },
    dark: Boolean,
    light: Boolean,
    solo: Boolean,
    filled: Boolean,
    flat: Boolean,
    required: Boolean,
    hint: {
      type: String,
      default: null
    },
    placeholder: {
      type: Object,
      default: null
    },
    multiple: {
      type: Boolean
    },
    filter: {
      type: Object,
      default: null
    },
    // eslint-disable-next-line vue/prop-name-casing
    'solo-inverted': Boolean,
    // eslint-disable-next-line vue/prop-name-casing
    'hide-no-data': Boolean,
    // eslint-disable-next-line vue/prop-name-casing
    'hide-details': Boolean,
    // eslint-disable-next-line
    'append-icon': String,
    // eslint-disable-next-line
    'prepend-icon': String,
  },
  data () {
    return {
      search: null,
      isLoading: false
    }
  },
  computed: {
    valueFormatted () {
      if (!this.multiple) return this.value
      if (this.value && !Array.isArray(this.value))
        return this.value.split('/')

      return []
    },
    Todos () {
      const obj = {}
      obj[this.itemValue] = '-1'
      obj[this.itemText] = 'TODOS'
      return [obj]
    },
    items () {
      if (this.opcionTodos)
        return this.Todos.concat(this.itemslist)

      return this.itemslist
    },
    itemslist () {
      return []
    },
    clearable () {
      if (this.disabled || this.readonly)
        return false

      return true
    },
    Deshabilitado () {
      return this.disabled
    }
  },
  watch: {

  },
  created () {},
  mounted () {},
  updated () {},
  deactivated () {},
  methods: {
    inputChange (event) {
      let result = event
      if (this.multiple && Array.isArray(event))
        result = event.join('/')

      this.$emit('input', result)
      this.change(event)
    },
    change () {} // Metodo Abstractos
  },
  destoyed () {}
}
</script>
