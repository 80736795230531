import api from '../../api/EstadoPagoApi'

/* eslint no-param-reassign: ["error", { "props": false }] */
export default {
  namespaced: true,
  state: {
    itemsCombo: []
  },
  getters: {},
  actions: {
    ListCombo ({ commit }) {
      const params = {}
      api.ListCombo(params)
        .then((result) => {
          commit('setItemsCombo', result)
        })
        .catch(() => {
          // console.dir(err);
        })
    }
  },
  mutations: {
    setItemsCombo (state, rows) {
      state.itemsCombo = rows
    }
  }
}
