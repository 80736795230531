import axios from 'axios'

const headers = { 'Content-Type': 'multipart/form-data' }

const getFormData = (params) => {
  if (!params.Archivo) return params
  const formData = new FormData()
  for (const key in params)
    formData.append(key, params[key])
  return formData
}

export default {
  ListCombo () {
    return new Promise((resolve, reject) => {
      // Recupera del cache la list de items
      // realizo el llamado ajax
      const p = axios.get('/api/pos_firmware/ListCombo')

      p.then(({ data }) => {
        sessionStorage.setItem('pos_firmware_itemscombo', JSON.stringify(data.recordset))
        resolve(data.recordset)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  List (params) {
    return axios.get('/api/pos_firmware/List', { params })
  },
  Agregar (params) {
    const formData = getFormData(params)
    return axios.post('/api/pos_firmware', formData, { headers })
  },
  Modificar (params) {
    const formData = getFormData(params)
    return axios.put('/api/pos_firmware', formData, { headers })
  },
  Borrar (params) {
    return axios.delete('/api/pos_firmware', { params })
  }
}
