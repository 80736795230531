import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import syncStorage from './plugins/syncStorage'
import permission from './modules/permission'
import settings from './modules/settings'
import user from './modules/user'
import Notify from './notify/Notify'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    permission,
    settings,
    user,
    Notify,
    ...modules
  },

  plugins: [
    syncStorage({})
  ],

  state: {},
  mutations: {
    setShowNotify (state, value) {
      state.showNotify = value
    }
  },
  actions: {},
  getters: {}
})
