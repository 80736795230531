import axios from 'axios'

export default {

  List (params) {
    return axios.get('/api/usuario_clientes/List', { params })
  },
  Agregar (params) {
    return axios.post('/api/usuario_clientes', params)
  },
  Modificar (params) {
    return axios.put('/api/usuario_clientes', params)
  },
  Borrar (params) {
    return axios.delete('/api/usuario_clientes', { params })
  }
}
