import axios from 'axios'

export default {
  ListCombo () {
    return new Promise((resolve, reject) => {
      const p = axios.get('/api/usuarios/ListCombo')

      p.then(({ data }) => {
        sessionStorage.setItem('usuarios_itemscombo', JSON.stringify(data.recordset))
        resolve(data.recordset)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  List (params) {
    return axios.get('/api/usuarios/List', { params })
  },
  Agregar (params) {
    return axios.post('/api/usuarios', params)
  },
  Modificar (params) {
    return axios.put('/api/usuarios', params)
  },
  Borrar (params) {
    return axios.delete('/api/usuarios', { params })
  },
  Login (params) {
    return axios.get('/api/usuarios/Login', { params })
  },
  ChangePerfil (params) {
    return axios.put('/api/usuarios/ChangePerfil', params)
  }
}
