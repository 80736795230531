/** When your routing table is too long, you can split it into small modules */

import Layout from '@/views/layout/TheLayout.vue'

const themeRouter = {
  path: '/Tema',
  component: Layout,
  redirect: 'noredirect',
  name: 'TemaGrp',
  meta: {
    title: 'Tema',
    icon: 'mdi-account-group'
  },
  children: [
    {
      path: '/theme',
      component: () => import('@/views/components/Theme.vue'),
      name: 'Tema',
      meta: { title: 'route.theme', icon: 'mdi-palette' }
    }

  ]
}

export default themeRouter
