import api from '../../api/Vending_PagosApi'

/* eslint no-param-reassign: ["error", { "props": false }] */
export default {
  namespaced: true,
  state: {
    items: [],
    itemsCombo: []
  },
  getters: {},
  actions: {
    /*
    ListCombo({ commit }) {
      const params = { };
      api.ListCombo(params)
        .then((result) => {
          commit('setItemsCombo', result);
        })
        .catch((err) => {
          console.dir(err);
        });
    },
    */
    List ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.List(params)
          .then((result) => {
            if (result.data.error)
              reject(result.data.error)
            else {
              commit('setItems', result.data.recordset)
              resolve(result.data.recordset)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /*
    RetornarPago({ commit }, item) {
      const params = {
        ...item,
      };
      return new Promise((resolve, reject) => {
        api.RetornarPago(params)
          .then((result) => {
            if (result.data.error) {
              reject(result.data.error);
            } else {
              const row = result.data.dbrow;
              commit('addItem', row);
              resolve(row);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    */
    RetornarPago ({ commit, state }, item) {
      const params = {
        ...item
      }
      return new Promise((resolve, reject) => {
        api.RetornarPago(params)
          .then((result) => {
            if (result.data.error)
              reject(result.data.error)
            else {
              const row = result.data.dbrow // seteo los nuevo valores desde la db
              // En lugar de agregar el nuevo item
              const index = state.items.findIndex((r) => true && r.idPago === row.idPago)
              if (index === -1) // Si no existe en el Array
                commit('addItem', row)
              else
                commit('editItem', { item: row, index })

              resolve(row)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
    /*
    Borrar({ commit, state }, item) {
      const params = {
        ...item,
      };
      return new Promise((resolve, reject) => {
        api.Borrar(params)
          .then((result) => {
            if (result.data.error) {
              reject(result.data.error);
            } else {
              // En lugar de agregar el nuevo item
              const index = state.items.findIndex((r) => true && r.idPago === params.idPago);
              if (index === -1) { // Si no existe en el Array
                // es un error
              } else {
                commit('removeItem', index);
              }
              resolve(params);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    */
  },
  mutations: {
    setItems (state, rows) {
      state.items = rows
    },
    setItemsCombo (state, rows) {
      state.itemsCombo = rows
    },
    addItem (state, item) {
      state.items.push(item)
    },
    editItem (state, { item, index }) {
      state.items.splice(index, 1, item)
    },
    removeItem (state, index) {
      state.items.splice(index, 1)
    }
  }
}
