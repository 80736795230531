/** When your routing table is too long, you can split it into small modules */

import Layout from '@/views/layout/TheLayout.vue'

const posRouter = {
  path: '/POS',
  component: Layout,
  redirect: '/PosEditxUsuario',
  name: 'POS',
  meta: {
    title: 'route.POS',
    icon: 'mdi-coffee-maker',
    roles: ['admin', 'editor']
  },
  children: [
    {
      path: '/PosEdit',
      component: () => import('@/views/pos/PosList.vue'),
      name: 'PosEdit',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.PosEdit', icon: 'mdi-translate', roles: ['admin'], noCache: true }
    },
    {
      path: '/PosDatos',
      component: () => import('@/views/pos/PosDatosList.vue'),
      name: 'PosDatos',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.PosDatos', icon: 'mdi-translate', roles: ['admin'], noCache: true }
    },
    {
      path: '/PosEditxUsuario',
      component: () => import('@/views/pos/PosListxUsuario.vue'),
      name: 'PosEditxUsuario',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.PosEditxUsuario', icon: 'mdi-translate', roles: ['admin', 'editor'], noCache: true }
    },
    {
      path: '/PosTipo',
      component: () => import('@/views/pos/Pos_TipoList.vue'),
      name: 'PosTipo',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.PosTipo', icon: 'mdi-translate', roles: ['admin'], noCache: true }
    },
    {
      path: '/PosFirmware',
      component: () => import('@/views/pos/Pos_FirmwareList.vue'),
      name: 'PosFirmware',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.PosFirmware', icon: 'mdi-translate', roles: ['admin'], noCache: true }
    },
    {
      path: '/PosVariables',
      component: () => import('@/views/pos/Pos_VariablesList.vue'),
      name: 'PosVariables',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.Variables', icon: 'mdi-translate', roles: ['admin'], noCache: true }
    },
    {
      path: '/PosEstado',
      component: () => import('@/views/pos/Pos_EstadoList.vue'),
      name: 'PosEstado',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.PosEstado', icon: 'mdi-translate', roles: ['admin'], noCache: true }
    },
    {
      path: '/PosMonitor',
      component: () => import('@/views/pos/Pos_Monitor.vue'),
      name: 'PosMonitor',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.PosMonitor', icon: 'mdi-translate', roles: ['admin'], noCache: true }
    },
    {
      path: '/VendingPagos',
      component: () => import('@/views/pos/Vending_PagosList.vue'),
      name: 'VendingPagos',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.VendingPagos', icon: 'mdi-translate', roles: ['admin', 'editor'], noCache: true }
    }
  ]
}

export default posRouter
