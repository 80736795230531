/**
 * Vuetify theme options.
 */
import axios from 'axios'

import { userAdmin, userEditor } from '@/api/mock'

/**
 * Login by email and password
 *
 * @param {String} email user email
 * @param {String} password user password
 */
/*
export const loginByUserName = (email, password) => new Promise((resolve, reject) => {
  console.log(`[loginByUserName] email ${email}`);

  const params = {
    Username: email,
    Password: password,
  };
  const user = axios.get('/api/usuarios/Login', { params });

  user.then(({ data }) => {
    resolve({
      // idSistema: token.idSistema,
      status: data.status, // puede ser OK o ERROR
      user: data.user,
      // cssHidden: response.data.cssHidden,
    });
  })
    .catch((err) => {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({
        // idSistema: token.idSistema,
        status: 'ERROR',
        // cssHidden: [],
        ...err,
      });
    });
});
*/
export const loginByEmail = async (email, password) => {
  // console.log(`[loginByEmail] email ${email}`)

  const params = {
    Username: email,
    Password: password
  }
  return axios.get('/api/usuarios/Login', { params })
}
/*
export const loginByEmail = async (email, password) => {
  console.log(`[loginByEmail] email ${email}`);

  const params = {
    Username: email,
    Password: password,
  };
  let user = axios.get('/api/usuarios/Login', { params });

  try {
    if (userEditor.email === email && userEditor.password === password) {
      user = userEditor;
    } else if (userAdmin.email === email && userAdmin.password === password) {
      user = userAdmin;
    }
    if (!user || !user.token) {
      throw new Error('User is not found');
    }
  } catch (err) {
    console.warn(`[loginByEmail] ${err}`);
  }
  return { user };
};
*/

/**
 * Get user information by token
 *
 * @param {String} token user token
 */
export const getUserInfo = async (token) => {
  // console.log(`[getUserInfo] token ${token}`)
  let user = {}
  try {
    if (!token)
      throw new Error('Invalid token')

    if (token === userAdmin.token)
      user = userAdmin
    else
      user = userEditor
  } catch (err) {
    console.warn(`[getUserInfo] ${err}`)
  }
  return { user }
}
