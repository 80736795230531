<template>
  <div class="v-application--wrap">
    <layoutToolbar :full-toolbar="false" />
    <layoutContent />
    <layoutToTopFab />
  </div>
</template>

<script>

import layoutContent from './TheLayoutContent.vue'
import layoutToTopFab from './TheLayoutToTopFab.vue'
import layoutToolbar from './TheLayoutToolbar.vue'

export default {
  name: 'LayoutBasic',
  components: {
    layoutContent,
    layoutToTopFab,
    layoutToolbar
  },
  data: () => ({})
}
</script>
