/** When your routing table is too long, you can split it into small modules */

import Layout from '@/views/layout/TheLayout.vue'

const reportesRouter = {
  path: '/ReportesApp',
  component: Layout,
  redirect: '/RptPOSProducido',
  name: 'Reportes',
  meta: {
    title: 'route.Reportes',
    icon: 'mdi-file-chart',
    roles: ['admin', 'editor', 'visitor']
  },
  children: [
    {
      path: '/RptPOSProducido',
      component: () => import('@/views/reportes/RptProducidoxPos.vue'),
      name: 'POSProducido',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.RptResultadoxPos', icon: 'mdi-translate', roles: ['admin', 'editor', 'visitor'], noCache: true }
    },
    {
      path: '/RptPOSProducidoxDia',
      component: () => import('@/views/reportes/RptProducidoxPosxDia.vue'),
      name: 'POSProducidoxDia',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.RptResultadoxPosxDia', icon: 'mdi-translate', roles: ['admin', 'editor', 'visitor'], noCache: true }
    },
    {
      path: '/RptPOSEventos',
      component: () => import('@/views/reportes/RptEventosxPos.vue'),
      name: 'POSEventos',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.RptPOSEventos', icon: 'mdi-file-chart', roles: ['admin', 'editor', 'visitor'], noCache: true }
    },
    {
      path: '/RptPOSPagos',
      component: () => import('@/views/reportes/RptPagosxPos.vue'),
      name: 'POSPagos',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.RptPagosPOS', icon: 'mdi-translate', roles: ['admin', 'editor', 'visitor'], noCache: true }
    },
    {
      path: '/RptPOSComunicacion',
      component: () => import('@/views/reportes/RptAuditComunicacion.vue'),
      name: 'POSComunicacion',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.RptPOSComunicacion', icon: 'mdi-translate', roles: ['admin'], noCache: true }
    },
    {
      path: '/RptPOSAlertas',
      component: () => import('@/views/reportes/RptAlertas.vue'),
      name: 'POSAlertas',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.RptPOSAlertas', icon: 'mdi-translate', roles: ['admin', 'editor', 'visitor'], noCache: true }
    },
    {
      path: '/RptLogSistema',
      component: () => import('@/views/reportes/RptLogSistema.vue'),
      name: 'LogSistema',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.RptLogSistema', icon: 'mdi-translate', roles: ['admin'], noCache: true }
    }
  ]
}

export default reportesRouter
