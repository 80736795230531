import Vue from 'vue'
import { setLocale } from '@/locale'

const app = {
  state: {
    ipAddress: null,
    languages: [
      {
        code: 'en-US',
        cultureInfo: 'en-US',
        flag: 'https://flagcdn.com/w80/us.jpg',
        languageId: 1,
        title: 'LAGUAGE_ENGLISH',
        lang_iso1: 'en'
      },
      {
        code: 'es-ES',
        cultureInfo: 'es-ES',
        flag: 'https://flagcdn.com/w80/es.jpg',
        languageId: 29,
        title: 'LAGUAGE_SPANISH',
        lang_iso1: 'es'
      }
    ],
    defaultLeanguaje: 'en-US',
    activeLanguage: null,
    LateralMenuStatusCollapsed: false,
    NameThemeAppActive: null,
    ThemeAppActive: null,
    whiteList: [
    ],
    SecureList: [
    ]
  },

  getters: {
    getLanguages (state) {
      return state.languages
    },
    getActiveLanguage (state) {
      return state.activeLanguage
    },
    getUserIP (state) {
      return (state.ipAddress ? state.ipAddress.ip : '0.0.0.0')
    },
    getLateralMenuStatusCollapsed (state) {
      return state.LateralMenuStatusCollapsed
    },
    getWhiteList (state) {
      return state.whiteList
    },
    getSecureList (state) {
      return state.SecureList
    },
    getConfig_showPartnerInfo () {
      return (process.env.VUE_APP_SHOW_PARTNER_INFO === Vue.prototype.$getDefineValue('PS_AFFIRMATIVE'))
    },
    getConfig_MAINTENANCE_MODE () {
      return (process.env.VUE_APP_MAINTENANCE_MODE === Vue.prototype.$getDefineValue('PS_AFFIRMATIVE'))
    },
    getConfig_VUE_APP_MAINTENANCE_IP () {
      return (process.env.VUE_APP_MAINTENANCE_IP)
    }
  },

  mutations: {
    setActiveLanguage (state, payload) {
      state.activeLanguage = payload
    },
    setIP (state, payload) {
      state.ipAddress = payload
    },
    setLateralMenuStatusCollapsed (state, payload) {
      state.LateralMenuStatusCollapsed = payload
    },
    setDinamicPages (state, payload) {
      state.whiteList = [...state.whiteList, ...payload]
    },
    setSecureList (state, payload) {
      state.SecureList = payload
    }
  },

  actions: {
    async changeSecureList ({ commit }, data) {
      commit('setSecureList', data)
    },
    async setLenguageDefault ({ commit, dispatch, state, rootState }) {
      const userLang = state.defaultLeanguaje //  navigator.language || navigator.userLanguage  tomaba el LENG del navegador

      let languageNavigator = state.languages.find(language => language.code === userLang)
      // const langWithoutCulture = userLang.split('-')[0]
      if (!languageNavigator) {
        const langWithoutCulture = userLang.split('-')[0]
        languageNavigator = state.languages.find(language => language.code.includes(langWithoutCulture))
      }
      if (state.activeLanguage)
        // this.$i18n.setLocale(state.activeLanguage.code)
        // rootState.settings.SetLocale = languageNavigator.code
        // dispatch('settings/SetLocale', languageNavigator.code, { root: true })
        await setLocale(languageNavigator.code)
      else {
        // rootState.settings.SetLocale = languageNavigator.code
        // dispatch('settings/SetLocale', { locale: languageNavigator.code }, { root: true })
        await setLocale(languageNavigator.code)
        commit('setActiveLanguage', languageNavigator)
      }
    },
    async getIpAddress ({ commit }) {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await Vue.prototype.$api.vms.getIP()
        commit('setIP', result)
      } catch (error) {
        throw error
      }
    },
    async ValidateIpAddress ({ commit }) {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await Vue.prototype.$api.generic.validateIP()
        commit('setIP', result.ip)
        return result
      } catch (error) {
        throw error
      }
    },
    async getDinamicPages ({ commit }) {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await Vue.prototype.$api.mktg_dynamic_pages.List({})
        if (result) {
          // get only slug
          let slug = [...new Set(result.map(p => p.slug))]
          // delete nulls values
          slug = slug.filter(slug => slug != null)

          commit('setDinamicPages', slug)
        }
      } catch (error) {
        throw error
      }
    }

  }
}

export default app
