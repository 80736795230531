<template>
  <v-menu
    offset-y
    origin="center center"
    :nudge-bottom="10"
    transition="scale-transition"
    max-width="400"
  >
    <template #activator="{on}">
      <v-btn
        icon
        text
        v-on="on"
      >
        <v-badge
          bottom
          color="red darken-4"
          dense
        >
          <span slot="badge">{{ itemsCount }}</span>
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card>
      <v-list
        class="pa-0"
        two-line
        subheader
      >
        <v-subheader>Alertes de POS</v-subheader>
        <v-divider />
        <!--<v-divider inset/>-->
        <!--<v-subheader inset>Files</v-subheader>-->
        <v-list-item
          v-for="item in result"
          :key="item.idAlerta"
          avatar
          @click="1"
        >
          <v-list-item-avatar>
            <v-icon :class="[item.CssAlerta]">
              {{ item.IconoAlerta }}
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ item.Alerta }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.Cliente }}-{{ item.FechaHora }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ item.Notas }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="primary"
          @click="VerReporte"
        >
          Ver Mas
          <v-icon right>
            mdi-magnify
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'AppNotification',
  data: () => ({
    params: {
      idCliente: null
    },
    action: 'Reportes/Execute',
    result: [],
    timer: ''
    /*
    items: [
      {
        icon: 'mdi-folder',
        iconClass: 'grey lighten-1 white--text',
        title: 'Photos',
        subtitle: 'Jan 9, 2014',
      },
      {
        icon: 'mdi-folder',
        iconClass: 'grey lighten-1 white--text',
        title: 'Recipes',
        subtitle: 'Jan 17, 2014',
      },
      {
        icon: 'mdi-folder',
        iconClass: 'grey lighten-1 white--text',
        title: 'Work',
        subtitle: 'Jan 28, 2014',
      },
    ],
    items2: [
      {
        icon: 'mdi-message',
        iconClass: 'blue white--text',
        title: 'Vacation itinerary',
        subtitle: 'Jan 20, 2014',
      },
      {
        icon: 'mdi-alarm',
        iconClass: 'amber white--text',
        title: 'Kitchen remodel',
        subtitle: 'Jan 10, 2014',
      },
    ],
    */
  }),
  computed: {
    itemsCount () {
      return this.result.length
    }
  },
  watch: {
    // el deep:  es para cuando se hace un watch de un OBJETO
    params:
      {
        handler (newValue) {
          this.params = newValue
          this.Recuperar()
        },
        deep: true

      }
  },
  mounted () {
    if (!this.$store.getters.GetEsAdministrador)
      this.params.idCliente = this.$store.getters.GetidCliente

    this.Recuperar()
  },
  created () {
    // cada 5 minutos 300000, controla las alertas
    this.timer = setInterval(this.Recuperar, 300000)
  },
  methods: {
    Recuperar () {
      this.Rpt_DashAlertas()
        .then((result) => {
          this.result = result.recordset
        })
        .catch(() => {
        })
    },
    Rpt_DashAlertas () {
      return this.$store.dispatch(this.action, {
        reporte: 'Rpt_DashAlertas',
        params: this.params
      })
    },
    VerReporte () {
      this.$router.push('RptPOSAlertas')
    },
    beforeDestroy () {
      clearInterval(this.timer)
    }
  }
}
</script>
