<template>
  <v-text-field
    :value="value"
    :readonly="readonly"
    :disabled="disabled"
    :label="label"
    :filled="filled"
    :dark="dark"
    :light="light"
    :counter="counter"
    :required="required"
    :rules="casRules"
    :mask="mask"
    :clearable="clearable"
    :type="currentType"
    :dense="dense"
    autocomplete="nope"
    :hint="hint"
    :persistent-hint="persistentHint"
    :append-icon="appendIcon"
    :prepend-icon="prependIcon"
    @click:append="togglePasswordVisibility"
    @input="onInput"
    @change="onChange"
    @focus="selectAll"
  />
</template>
<script>
import casRuleable from './mixins/casRuleable'

export default {
  name: 'CasTextBox',
  components: {},
  mixins: [casRuleable],
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    dark: {
      type: Boolean
    },
    light: {
      type: Boolean
    },
    filled: {
      type: Boolean
    },
    mask: {
      type: String,
      default: ''
    },
    counter: {
      type: [String, Number],
      default: 20
    },
    type: {
      type: String,
      default: 'text' /* la otra opcion es "password"  */
    },
    clearable: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: true
    },
    dense: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: false
    },
    hint: {
      type: String,
      default: null
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showPassword: false
    }
  },
  computed: {
    appendIcon () {
      if (!this.isPassword) return null
      return this.showPassword ? 'mdi-eye' : 'mdi-eye-off'
    },
    currentType () {
      if (this.isPassword && !this.showPassword)
        return 'password'
      return 'text'
    },
    isPassword () {
      return this.type === 'password'
    },
    isAdmin () {
      return this.$store.getters.GetEsAdministrador
    }
  },
  mounted () {},
  methods: {
    selectAll (event) {
      //   // Workaround for Safari bug
      //   // http://stackoverflow.com/questions/1269722/selecting-text-on-focus-using-jquery-not-working-in-safari-and-chrome
      setTimeout(() => {
        event.target.select()
      }, 0)
    },
    onInput (event) {
      this.$emit('input', event)
    },
    onChange (event) {
      this.$emit('change', event)
    },
    togglePasswordVisibility () {
      this.showPassword = !this.showPassword
    }
  }
}
</script>
