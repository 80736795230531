import axios from 'axios'

export default {
  ListCombo () {
    return new Promise((resolve, reject) => {
      // Recupera del cache la list de items
      // realizo el llamado ajax
      const p = axios.get('/api/pos_variables/ListCombo')

      p.then(({ data }) => {
        sessionStorage.setItem('por_variables_itemscombo', JSON.stringify(data.recordset))
        resolve(data.recordset)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  List (params) {
    return axios.get('/api/pos_variables/List', { params })
  },
  Agregar (params) {
    return axios.post('/api/pos_variables', params)
  },
  Modificar (params) {
    return axios.put('/api/pos_variables', params)
  },
  Borrar (params) {
    return axios.delete('/api/pos_variables', { params })
  }
}
