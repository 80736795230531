<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      absolute
      offset-x
      :position-x="menuPosition.x"
      :position-y="menuPosition.y"
      max-width="400"
    >
      <template #activator="{on}">
        <v-list-item
          ripple="ripple"
          rel="noopener"
          v-on="on"
        >
          <v-list-item-action>
            <v-icon>mdi-account</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Perfil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <div>
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-card>
            <v-card-title
              class="white--text align-end primary"
            >
              Usuario:  {{ user.user }}
              <v-spacer />
              <v-btn
                icon
                dark
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </v-card-title>

            <v-container>
              <v-row dense>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <casTextBox
                    v-model="params.Cliente"
                    label="Cliente"
                    disabled
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <casTextBox
                    v-model="params.Nombre"
                    label="Nombre y Apellido"
                    disabled
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <casTextBox
                    v-model="params.EMail"
                    label="E-Mail"
                    counter="100"
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <casTextBox
                    v-model="params.Password"
                    label="Contraseña Actual"
                    append-icon="visibiliy_off"
                    type="password"
                    required
                    counter="30"
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <casTextBox
                    v-model="params.PasswordNueva"
                    label="Contraseña Nueva"
                    counter="30"
                    type="password"
                    required
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <casTextBox
                    v-model="params.PasswordNuevaVerificada"
                    label="Repetir Contraseña Nueva"
                    counter="30"
                    type="password"
                    required
                    dense
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-divider />

            <v-card-actions>
              <v-spacer />
              <v-btn
                v-if="!showGuardando"
                @click="menu = false"
              >
                Cancelar
                <v-icon right>
                  mdi-close-circle
                </v-icon>
              </v-btn>
              <v-btn
                v-if="showGuardar"
                class="primary"
                @click="guardar"
              >
                Guardar
                <v-icon right>
                  mdi-content-save
                </v-icon>
              </v-btn>
              <v-progress-circular
                v-if="showGuardando"
                indeterminate
                color="green"
              />
            </v-card-actions>
          </v-card>
        </v-form>
      </div>
    </v-menu>
  </div>
</template>

<script>
// import casFotoLegajo from '@/CCMdp/components/casFotoLegajo';

/* eslint-disable indent */
import casTextBox from '../../components/casTextBox.vue'

export default {
  name: 'PerfilUsuario',
  components: { casTextBox },
  props: {
    pActiveRow: {
      type: Object,
      default: () => {},
      required: false
    }
  },
  data () {
    return {
      menu: false,
      validForm: true,
      requiredRules: [
        (v) => !!v || 'el valor es obligatorio'
      ],
      params: {
        idUsuario: null,
        EMail: null,
        Nombre: null,
        Cliente: null,
        Password: null,
        PasswordNueva: null,
        PasswordNuevaVerificada: null
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    activeRow () {
      return this.pActiveRow
    },
    disabled () {
      return true
    },
    showGuardar () {
      switch (this.operacion) {
      case 'Ver':
      case 'Guardando':
        return false
      default:
        return true
      }
    },
    showGuardando () {
      switch (this.operacion) {
      case 'Guardando':
        return true
      default:
        return false
      }
    },
    menuPosition () {
      return { x: this.windowSize - 200, y: 130 }
    }

  },
  mounted () {
    this.params.idCliente = this.$store.getters.GetidCliente
    this.params.idUsuario = this.$store.state.user.idUsuario
    this.params.EMail = this.$store.state.user.EMail
    this.params.Nombre = this.$store.state.user.name
    this.params.Cliente = this.$store.state.user.Cliente
    this.params.Password = ''
    this.params.PasswordNueva = ''
    this.params.PasswordNuevaVerificada = ''
    this.onResize()
  },
  methods: {
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    guardar () {
      if (this.$refs.form.validate() || this.operacion === 'Borrar') {
        if (this.validarPasswords())
          // this.$emit('guardar', this.params);
          this.UpdatePerfil(this.params)
      } else
        this.$store.dispatch('Notify/changeShowNotify', {
          colorNotify: 'error',
          textNotify: 'Los valores ingresados no se corresponden con los formatos esperados'
        })
    },
    UpdatePerfil (activeRow) {
      this.operacion = 'Guardando'
      const pSave = this.$store.dispatch('Usuarios/ChangePerfil', activeRow)
      pSave.then(() => {
        this.showDialog = false
        this.$store.dispatch('Notify/changeShowNotify', {
          colorNotify: 'success',
          textNotify: 'Se han actualizado los datos exitosamente',
          timeoutNotify: 1500
        })

        // haggo el logout
        this.logout()
      }).catch((error) => {
        this.$store.dispatch('Notify/changeShowNotify', error)
      })

      return 0
    },
    validarPasswords () {
      if (this.params.PasswordNueva !== this.params.PasswordNuevaVerificada) {
        this.$store.dispatch('Notify/changeShowNotify', {
          colorNotify: 'error',
          textNotify: 'Las Contraseñas Nuevas NO coinciden, Verifique!!'
        })
        return false
      }

      if (this.params.PasswordNueva.length < 6) {
        this.$store.dispatch('Notify/changeShowNotify', {
          colorNotify: 'error',
          textNotify: 'La Contraseña debe tener como mínimo 6 caracteres, Verifique!!'
        })
        return false
      }

      return true
    },
    logout () {
      this.menu = false
      this.loggingOut = true
      this.$nextTick()
        .then(() => {
          // DOM updated
          this.$store.dispatch('LogOut')
            .then(() => {
              this.loggingOut = false
              this.$router.push('/login')
            })
        })
    }
  }
}
</script>
