/** When your routing table is too long, you can split it into small modules */

import Layout from '@/views/layout/TheLayout.vue'

const seguridadRouter = {
  path: '/Seguridad',
  component: Layout,
  redirect: '/UsuarioPerfil',
  name: 'Seguridad',
  meta: {
    title: 'route.Seguridad',
    icon: 'mdi-account-lock'
  },
  children: [
    /*
      {
        path: '/UsuarioPOS',
        component: () => import('@/views/usuarios/Usuario_PosList.vue'),
        name: 'UsuarioPOS',
        // eslint-disable-next-line object-curly-newline
        meta: { title: 'route.UsuarioPOS', icon: 'mdi-translate', roles: ['admin'], noCache: true }
      },
      */
    {
      path: '/UsuarioPerfil',
      component: () => import('@/views/usuarios/Usuario_PerfilList.vue'),
      name: 'UsuarioPerfil',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.UsuarioPerfil', icon: 'mdi-translate', roles: ['admin'], noCache: true }
    },
    {
      path: '/Usuarios',
      component: () => import('@/views/usuarios/UsuariosList.vue'),
      name: 'Usuarios',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.Usuarios', icon: 'mdi-translate', roles: ['admin'], noCache: true }
    }
  ]
}

export default seguridadRouter
