const requireModule = require.context('.', false, /\.js$/)
const modules = {}

requireModule.keys().forEach((fileName) => {
  if (fileName === './index.js' || fileName === './permission.js' || fileName === './settings.js' || fileName === './user.js') return

  const moduleName = fileName.replace(/(\.\/|\.js)/g, '')
  const requiredModule = requireModule(fileName)
  modules[moduleName] = {
    namespaced: true,
    ...(requiredModule.default || requiredModule)
  }
})

export default modules
