import axios from 'axios'

export default {
  ListCombo () {
    return new Promise((resolve, reject) => {
      // Recupera del cache la list de items
      const storage = sessionStorage.getItem('pos_estado_itemscombo')

      // Si ya estan los datos en el storage, se retorna de ahi, solo para las paramtétricas
      if (storage)
        resolve(JSON.parse(storage))
      else {
        // realizo el llamado ajax
        const p = axios.get('/api/pos_estado/ListCombo')

        p.then(({ data }) => {
          sessionStorage.setItem('pos_estado_itemscombo', JSON.stringify(data.recordset))
          resolve(data.recordset)
        }).catch((err) => {
          reject(err)
        })
      }
    })
  },
  List (params) {
    return axios.get('/api/pos_estado/List', { params })
  },
  Agregar (params) {
    return axios.post('/api/pos_estado', params)
  },
  Modificar (params) {
    return axios.put('/api/pos_estado', params)
  },
  Borrar (params) {
    return axios.delete('/api/pos_estado', { params })
  }
}
