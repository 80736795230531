import Vue from 'vue'
import Router from 'vue-router'

import Layout from '@/views/layout/TheLayout.vue'

/* Router Modules */
/* import vuetifyRouter from '@/demo/router/vuetify'; */
/* import nestedRouter from '@/demo/router/nested'; */
/* import componentsRouter from '@/demo/router/components'; */
import authRouter from './modules/auth'
/* import errorsRouter from './modules/errors';
import permissionRouter from './modules/permission'; */
import seguridadRouter from './modules/seguridad'
import infoclientesRouter from './modules/infoclientes'
import posRouter from './modules/pos'
import themeRouter from './modules/theme'
import reportesRouter from './modules/reportes'
import playeroRouter from './modules/playero'
// para prevenir el error de llamar a la misma url activa
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

/** note: sub-menu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 */

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its
 *                                child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    will control the page roles (you can set multiple roles)
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    [expected] noCache: true     if true, the page will no be cached(default is false)
    [expected] breadcrumb: false if false, the item will hidden in breadcrumb(default is true)
    [expected] affix: true       if true, the tag will affix in the tags-view
  }
 */

export const constantRoutes = [
  /*
  {
    path: '/landing',
    alias: '/land',
    component: () => import('@/views/auth/Landing.vue'),
    hidden: true
  },
  */
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/dashboard',
        alias: '/',
        component: () => import('@/views/dashboard/Dashboard_fv.vue'),
        name: 'Dashboard',
        meta: {
          title: 'route.dashboardfv', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },
      {
        hidden: true,
        path: '/404',
        component: () => import('@/views/error/ErrorPage.vue'),
        name: 'Page404',
        meta: { title: 'Error 404' },
        props: { errorCode: 404 }
      },
      {
        hidden: true,
        path: '/403',
        component: () => import('@/views/error/ErrorPage.vue'),
        name: 'Page403',
        meta: { title: 'Error 403' },
        props: { errorCode: 403 }
      }
    ]
  },
  ...authRouter
]

export const asyncRoutes = [
  posRouter,
  reportesRouter,
  infoclientesRouter,
  seguridadRouter,
  themeRouter,
  playeroRouter,
  { path: '*', redirect: '/404', hidden: true }
]
export default new Router({
  mode: 'history', // gh-pages
  scrollBehavior: () => ({ y: 0 }),
  // routes: constantRoutes
  routes: constantRoutes.concat(asyncRoutes)
})
