import axios from 'axios'

export default {
  Start (params) {
    return axios.get('/api/monitor/start', { params })
  },
  Stop () {
    return axios.get('/api/monitor/stop')
  },
  List (params) {
    return axios.get('/api/monitor/list', { params })
  }
}
