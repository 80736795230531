import moment from 'moment'

/* eslint no-param-reassign: ["error", { "props": false }] */
export default {
  namespaced: true,
  state: {
    showNotify: null,
    listNotify: []
  },
  getters: {},
  actions: {
    changeShowNotify ({ commit }, newValue) {
      const noty = {
        colorNotify: 'error', // 'success', 'info', 'error', 'cyan darken-2'
        textNotify: 'Ocurrió un error en la última acción',
        headNotify: 'Ocurrió un error ...',
        timeoutNotify: 6000,
        timestampNotify: moment(new Date()).format('DD/MM/YYYY H:mm:ss')
      }

      // console.log(newValue);
      // Se trata de un error
      if (newValue.originalError) {
        noty.colorNotify = 'error'
        noty.textNotify = 'Ocurrió un error en la última acción '
        if (newValue.serverName)
          noty.textNotify += ` Server: ${newValue.serverName}`

        if (newValue.originalError.info)
          noty.textNotify += ` Message: ${newValue.originalError.info.message}`

        if (newValue.originalError.message)
          noty.textNotify += ` Message: ${newValue.originalError.message}`
      } else if ((newValue.response) &&
            (newValue.response.data) &&
            (newValue.response.data.error)) {
        noty.colorNotify = 'error'
        noty.textNotify = ` Message: ${newValue.response.data.error}`
      } else if (newValue.message) {
        noty.colorNotify = 'error'
        noty.textNotify = 'Ocurrió un error en la última acción '
        noty.textNotify += ` Message: ${newValue.message}`
      } else if (newValue.colorNotify) {
        noty.colorNotify = newValue.colorNotify // 'success', 'info', 'error', 'cyan darken-2'
        noty.textNotify = newValue.textNotify
        noty.timeoutNotify = (newValue.timeoutNotify) ? newValue.timeoutNotify : 6000
      } else if (newValue.textNotify) {
        noty.colorNotify = (newValue.colorNotify) ? newValue.colorNotify : 'error'
        noty.textNotify = newValue.textNotify
        noty.timeoutNotify = (newValue.timeoutNotify) ? newValue.timeoutNotify : 6000
      } else if (typeof newValue === 'string')
        noty.textNotify = newValue

      noty.headNotify = (noty.textNotify) ? `${noty.textNotify.substring(0, 40)} ...` : ''
      commit('setShowNotify', noty)
      if (noty.colorNotify === 'error')
        commit('addNotify', noty)
    },
    clearListNotify ({ commit }) {
      commit('clearNotify')
    }
  },
  mutations: {
    setShowNotify (state, value) {
      state.showNotify = value
    },
    addNotify (state, item) {
      state.listNotify.unshift(item)
    },
    clearNotify (state) {
      state.listNotify = []
    }
  }
}
