import axios from 'axios'

/* eslint-disable max-len */
// set auth header
// axios.defaults.headers.common.Authorization = `Bearer ${JSON.parse(localStorage.getItem('fraven_user')).token}`;

export default {
  /*
  ListCombo() {
    return new Promise((resolve, reject) => {
      // Recupera del cache la list de items
      const storage = sessionStorage.getItem('clientes_itemscombo');

      // Si ya estan los datos en el storage, se retorna de ahi, solo para las paramtétricas
      if (storage) {
        resolve(JSON.parse(storage));
      } else {
        // realizo el llamado ajax
        const p = axios.get('/api/clientes/ListCombo');

        p.then(({ data }) => {
          sessionStorage.setItem('clientes_itemscombo', JSON.stringify(data.recordset));
          resolve(data.recordset);
        }).catch((err) => {
          reject(err);
        });
      }
    });
  },
  */
  ListCombo (params) {
    return new Promise((resolve, reject) => {
      const p = axios.get('/api/clientes/ListCombo', { params })

      p.then(({ data }) => {
        sessionStorage.setItem('clientes_itemscombo', JSON.stringify(data.recordset))
        resolve(data.recordset)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  List (params) {
    return axios.get('/api/clientes/List', { params })
  },
  Agregar (params) {
    return axios.post('/api/clientes', params)
  },
  Modificar (params) {
    return axios.put('/api/clientes', params)
  },
  Borrar (params) {
    return axios.delete('/api/clientes', { params })
  },
  MPStore (params) {
    return axios.post('/api/mercadopago/store', params)
  }
}
