// import components from '@/demo/locale/en_US/components';
import route from './route'

export default {
  route,
  // components,
  toolbar: {
    appname: 'Farina Vending',
    settings: 'Configuración',
    profile: 'Perfil',
    logout: 'Salir'
  },
  settings: {
    title: 'Theme Settings',
    default: 'Set x Defecto',
    version: 'Versión',
    position: 'Posición de Items',
    theme: 'Tema',
    dark: 'Tema Dark',
    toolbarDense: 'Toolbar dense',
    navbarDense: 'Navbar dense',
    navbarLogo: 'Navbar logo',
    settingsBtn: 'Setting btn',
    footer: 'Footer'
  },
  login: {
    titleIn: 'Login',
    titleUn: 'Registro',
    singIn: 'Validarse',
    singUp: 'Registrarse',
    email: 'E-Mail',
    password: 'Contraseña',
    confirm: 'Confirmar'
  },
  errors: {
    whoops: 'Whoops',
    back: 'Sacarme de Acá!!!!',
    301: 'Movido Permanentemente',
    401: 'Acceso NO Autorizado',
    403: 'Forbidden',
    404: 'No Encontrado',
    500: 'Erro de Server '
  },
  guide: {
    description: 'The guide page is useful for some people who entered the project for ' +
      'the first time. You can briefly introduce the features of the project. Demo is based on',
    button: 'Show Guide'
  },
  ui: {
    switch: 'Switch Languaje',
    theme: 'Modificar Tema',
    success: 'Exito',
    error: 'Error',
    warning: 'Warning',
    info: 'Info',
    primary: 'Primario',
    secondary: 'Secundario',
    default: 'Default',
    accent: 'Accent',
    firstName: 'Nombre',
    lastName: 'Apellido',
    email: 'E-Mail'
  }
}
