/** When your routing table is too long, you can split it into small modules */
import Layout from '@/views/layout/TheLayout.vue'

const authRouter = [

  {
    path: '/login',
    alias: '/singin',
    component: () => import('@/views/auth/SingIn.vue'),
    hidden: true
  }

]

export default authRouter
