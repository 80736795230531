/** When your routing table is too long, you can split it into small modules */

import Layout from '@/views/layout/TheLayout.vue'

const infoclientesRouter = {
  path: '/ClientesApp',
  component: Layout,
  redirect: '/Clientes',
  name: 'ClientesApp',
  meta: {
    title: 'route.Clientes',
    icon: 'mdi-account-group',
    roles: ['admin']
  },
  children: [
    {
      path: '/Clientes',
      component: () => import('@/views/clientes/ClientesList.vue'),
      name: 'Clientes',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.Clientes', icon: 'mdi-account-group', roles: ['admin'], noCache: true }
    },
    {
      path: '/UsuarioPOS',
      component: () => import('@/views/usuarios/Usuario_PosList.vue'),
      name: 'UsuarioPOS',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.UsuarioPOS', icon: 'mdi-translate', roles: ['admin'], noCache: true }
    }
  ]
}

export default infoclientesRouter
