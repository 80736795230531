import axios from 'axios'

export default {
  /*
  ListCombo() {
    return new Promise((resolve, reject) => {
      // Recupera del cache la list de items
      const storage = sessionStorage.getItem('vending_pagos2_itemscombo');

      // Si ya estan los datos en el storage, se retorna de ahi, solo para las paramtétricas
      if (storage) {
        resolve(JSON.parse(storage));
      } else {
        // realizo el llamado ajax
        const p = axios.get('/api/vending_pagos2/ListCombo');

        p.then(({ data }) => {
          sessionStorage.setItem('vending_pagos2_itemscombo', JSON.stringify(data.recordset));
          resolve(data.recordset);
        }).catch((err) => {
          reject(err);
        });
      }
    });
  },
  */
  List (params) {
    return axios.get('/api/vending_pagos/List', { params })
  },
  RetornarPago (params) {
    return axios.put('/api/vending_pagos/RetornarPago', params)
  }
  /*
  Modificar(params) {
    return axios.put('/api/vending_pagos2', params);
  },
  Borrar(params) {
    return axios.delete('/api/vending_pagos2', { params });
  },
  */
}
