import Layout from '@/views/layout/TheLayoutBasic.vue'

const playeroRoutes = {
  path: '/PlayeroApp',
  component: Layout,
  redirect: 'noredirect',
  name: 'PlayeroApp',
  meta: {
    title: 'Playero',
    icon: 'mdi-account-group'
  },
  children: [
    {
      path: '/playero',
      component: () => import('@/views/pos/Playero.vue'),
      name: 'Playero',
      meta: { title: 'Playero', icon: 'mdi-human-greeting-proximity' }
    }

  ]
}

export default playeroRoutes
