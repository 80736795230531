<template>
  <v-main class="ma-3">
    <transition name="fade-transform" mode="out-in">
      <!-- <keep-alive>
        <router-view />
      </keep-alive> -->
      <router-view />
    </transition>
    <casNotify />
    <v-dialog v-model="showMQTTMessageDialog" width="800px" :fullscreen="$vuetify.breakpoint.smAndDown">
      <mqttMessageDialog />
    </v-dialog>
  </v-main>
</template>

<script>
import casNotify from '../../components/casNotify.vue'
import mqttMessageDialog from '../pos/mqttMessageDialog.vue'

export default {
  name: 'TheLayoutContent',
  components: { casNotify, mqttMessageDialog },
  data: () => ({}),
  computed: {
    showMQTTMessageDialog: {
      get () {
        return this.$store.state.MensajeMQTT.showMQTTMessageDialog
      },
      set (value) {
        this.$store.commit('MensajeMQTT/setShowMQTTMessageDialog', value)
      }
    }
  }
}
</script>
