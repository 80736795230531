import axios from 'axios'

export default {
  ListCombo () {
    return new Promise((resolve, reject) => {
      const p = axios.get('/api/usuario_pos/ListCombo')

      p.then(({ data }) => {
        sessionStorage.setItem('usuario_pos_itemscombo', JSON.stringify(data.recordset))
        resolve(data.recordset)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  List (params) {
    return axios.get('/api/usuario_pos/List', { params })
  },
  Agregar (params) {
    return axios.post('/api/usuario_pos', params)
  },
  Modificar (params) {
    return axios.put('/api/usuario_pos', params)
  },
  Borrar (params) {
    return axios.delete('/api/usuario_pos', { params })
  }
}
