import axios from 'axios'

export default {
  ListCombo () {
    return new Promise((resolve, reject) => {
      // Recupera del cache la list de items
      // realizo el llamado ajax
      const p = axios.get('/api/alertas_tipo/ListCombo')

      p.then(({ data }) => {
        sessionStorage.setItem('alertas_tipo_itemscombo', JSON.stringify(data.recordset))
        resolve(data.recordset)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  List (params) {
    return axios.get('/api/alertas_tipo/List', { params })
  }

}
