import api from '../../api/PosApi'

/* eslint no-param-reassign: ["error", { "props": false }] */
export default {
  namespaced: true,
  state: {
    items: [],
    itemsCombo: [],
    itemsxUsuario: []
  },
  getters: {},
  actions: {
    ListCombo ({ commit }) {
      const params = { }
      api.ListCombo(params)
        .then((result) => {
          commit('setItemsCombo', result)
        })
        .catch((err) => {
          console.dir(err)
        })
    },
    List ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.List(params)
          .then((result) => {
            if (result.data.error)
              reject(result.data.error)
            else {
              commit('setItems', result.data.recordset)
              resolve(result.data.recordset)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    ListxUsuario ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.ListxUsuario(params)
          .then((result) => {
            if (result.data.error)
              reject(result.data.error)
            else {
              commit('setItemsxUsuario', result.data.recordset)
              resolve(result.data.recordset)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    Agregar ({ commit }, item) {
      const params = {
        ...item
      }
      return new Promise((resolve, reject) => {
        api.Agregar(params)
          .then((result) => {
            if (result.data.error)
              reject(result.data.error)
            else {
              const row = result.data.dbrow
              commit('addItem', row)
              resolve(row)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    Modificar ({ commit, state }, item) {
      const params = {
        ...item
      }
      return new Promise((resolve, reject) => {
        api.Modificar(params)
          .then((result) => {
            if (result.data.error)
              reject(result.data.error)
            else {
              const row = result.data.dbrow // seteo los nuevo valores desde la db
              // En lugar de agregar el nuevo item
              const index = state.items.findIndex((r) => true && r.idPos === row.idPos)
              if (index === -1) // Si no existe en el Array
                commit('addItem', row)
              else
                commit('editItem', { item: row, index })

              resolve(row)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    Borrar ({ commit, state }, item) {
      const params = {
        ...item
      }
      return new Promise((resolve, reject) => {
        api.Borrar(params)
          .then((result) => {
            if (result.data.error)
              reject(result.data.error)
            else {
              // En lugar de agregar el nuevo item
              const index = state.items.findIndex((r) => true && r.idPos === params.idPos)
              if (index === -1) { // Si no existe en el Array
                // es un error
              } else
                commit('removeItem', index)

              resolve(params)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /* eslint no-empty-pattern: "error" */
    UpdatePos ({ commit }, item) {
      return api.UpdatePos(item, commit)
    },
    /* eslint no-empty-pattern: "error" */
    StatusPos ({ commit }, item) {
      return api.StatusPos(item, commit)
    },
    /* eslint no-empty-pattern: "error" */
    DatosConfPos ({ commit }, item) {
      return api.DatosConfPos(item, commit)
    },
    MPCaja ({ commit, state }, item) {
      const params = {
        ...item
      }
      return new Promise((resolve, reject) => {
        api.MPCaja(params)
          .then((result) => {
            if (result.data.error)
              reject(result.data.error)
            else {
              const row = result.data.dbrow // seteo los nuevo valores desde la db
              // En lugar de agregar el nuevo item
              const index = state.items.findIndex((r) => true && r.idPos === row.idPos)
              if (index === -1) // Si no existe en el Array
                commit('addItem', row)
              else
                commit('editItem', { item: row, index })

              resolve(row)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    ActualizarPrecio ({ commit, state }, item) {
      const params = {
        ...item
      }
      return new Promise((resolve, reject) => {
        api.ActualizarPrecio(params)
          .then((result) => {
            if (result.data.error)
              reject(result.data.error)
            else {
              const row = result.data.dbrow // seteo los nuevo valores desde la db
              resolve(row)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    ActualizarDatos ({ commit, state }, item) {
      // TODO: Setear valores por defecto
      const params = {
        ...item
      }
      return new Promise((resolve, reject) => {
        api.ActualizarDatos(params)
          .then((result) => {
            if (result.data.error)
              reject(result.data.error)
            else {
              const row = result.data.dbrow // seteo los nuevo valores desde la db
              resolve(row)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    DatosList ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.DatosList(params)
          .then((result) => {
            if (result.data.error)
              reject(result.data.error)
            else {
              commit('setItems', result.data.recordset)
              resolve(result.data.recordset)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  },
  mutations: {
    setItems (state, rows) {
      state.items = rows
    },
    setItemsxUsuario (state, rows) {
      state.itemsxUsuario = rows
    },
    setItemsCombo (state, rows) {
      state.itemsCombo = rows
    },
    addItem (state, item) {
      state.items.push(item)
    },
    editItem (state, { item, index }) {
      state.items.splice(index, 1, item)
    },
    removeItem (state, index) {
      state.items.splice(index, 1)
    }
  }
}
