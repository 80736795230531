/**
 * ReportesApi
 * Api para los reportes
 * Creado 2018-06-11
 * Autor Francisco Pane
 *
 */
import axios from 'axios'

export default {
  /**
   * Execucion de Reporte Generico
   */
  Execute ({ reporte, params }) {
    return new Promise((resolve, reject) => {
      const p = axios.get(`/api/reportes/Execute/${reporte}`,
        { params })
      p.then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}
