/* eslint-disable max-len */
import api from '../../api/MonitorApi'

/* eslint no-param-reassign: ["error", { "props": false }] */
export default {
  namespaced: true,
  state: {
    items: [],
    itemsCombo: []
  },
  getters: {},
  actions: {
    Start ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.Start(params, commit)
          .then((result) => {
            if (result.data.error)
              reject(result.data.error)
            else
              resolve(result.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    Stop () {
      return new Promise((resolve, reject) => {
        api.Stop()
          .then((result) => {
            if (result.data.error)
              reject(result.data.error)
            else
              resolve(result.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    List ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.List(params)
          .then((result) => {
            if (result.data.error)
              reject(result.data.error)
            else {
              commit('setItems', result.data.recordset)
              resolve(result.data)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  },
  mutations: {
    setItems (state, rows) {
      state.items = rows
    },
    setItemsCombo (state, rows) {
      state.itemsCombo = rows
    },
    addItem (state, item) {
      state.items.push(item)
    },
    editItem (state, { item, index }) {
      state.items.splice(index, 1, item)
    }
  }
}
