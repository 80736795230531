<template>
  <v-snackbar
    v-model="viewNotify"
    :timeout="timeoutNotify"
    :color="colorNotify"
    bottom
    right
    multi-line
  >
    {{ textNotify }}
    <v-btn
      class="mx-2"
      fab
      dark
      small
      :color="colorNotify"
      @click.native="viewNotify = false"
    >
      <v-icon dark>
        mdi-close-circle-outline
      </v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: 'CasNotify',
  data () {
    return {
      viewNotify: false,
      colorNotify: 'error', // 'success', 'info', 'error', 'cyan darken-2'
      textNotify: 'Ocurrió un error en la última acción',
      timeoutNotify: 6000
    }
  },
  computed: {
    showNotify () {
      return this.$store.state.Notify.showNotify
    }
  },
  watch: {
    showNotify (newValue) {
      this.colorNotify = newValue.colorNotify // 'success', 'info', 'error', 'cyan darken-2'
      this.textNotify = newValue.textNotify
      this.timeoutNotify = (newValue.timeoutNotify) ? newValue.timeoutNotify : 6000
      this.viewNotify = true
    }
  },
  created () {
  },
  methods: {
  }
}

</script>
