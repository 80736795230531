import NProgress from 'nprogress' // progress bar
import axios from 'axios'
import router, { constantRoutes } from './index'
import store from '../store'
import authRouter from './modules/auth'
import 'nprogress/nprogress.css' // progress bar style

NProgress.configure({ showSpinner: false }) // NProgress Configuration

/**
 * Generate white list
 */

const whiteList = (['/landing', '/land']
  .concat(Array.from(authRouter, (route) => route.path))
  .concat(Array.from(authRouter, (route) => route.alias)))
  .filter((route) => route) // remove undefined element
// console.log('[router.whiteList]', whiteList)

/**
 * Check user has permission for this routes.
 * 'admin' permission passed directly.
 * @param {Array} roles
 * @param {Array} permissionRoles
 */
function hasPermission (roles, permissionRoles) {
  if (roles.includes('admin')) return true
  if (!permissionRoles) return true
  // return roles.some((role) => permissionRoles.includes(role)) comente 2023
  return permissionRoles.includes(roles)
}

function getwhiteList () {
  /*
    const List = objRouter.map(function (route) {
      const ChildrenPaths = route.children.map(function (routech) { return routech.path })
      return [route.path, ...ChildrenPaths]
    })

    const merged = List.reduce(function (prev, next) { return prev.concat(next) })
    */

  const whiteList = []
  constantRoutes.forEach(function (obj) {
    whiteList.push(obj.path)
    if (obj.alias)
      whiteList.push(obj.alias)
    obj.children.forEach(function (obj2) {
      whiteList.push(obj2.path)
      if (obj2.alias)
        if (!Array.isArray(obj2.alias))
          whiteList.push(obj2.alias)
        else
          obj2.alias.forEach(element => whiteList.push(element))
    })
  })

  store.dispatch('app/changeSecureList', whiteList, { root: true })
  return whiteList
}

function includePath (whiteList, to) {
  let pathSearch = to.path
  let search = whiteList.includes(pathSearch)
  // si es falso , valido que no sea un path con params
  if (!search && to.params && Object.keys(to.params).length > 0) {
    pathSearch = pathSearch.substring(0, pathSearch.lastIndexOf('/'))
    // valido sin el param
    search = whiteList.includes(pathSearch)
  }

  return search
}

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  let logMsg = '[router.beforeEach]'

  try {
    const activeToken = (store.getters.token ? store.getters.token : null)
    let activeUser = store.getters.user
    let activeRole = store.getters.roles // store.getters['user/roles']
    // const whiteList = [] // getwhiteList()
    // determine if there has token
    if (activeToken) {
      logMsg += '\t[token]'
      if (whiteList.includes(to.path)) {
        logMsg += '\t[whiteList]'
        next({ path: '/' })
      } else {
        logMsg += '\t[!whiteList]'
        if (!activeRole || activeRole.length === 0) {
          logMsg += `\t[roles=${activeRole}]`
          // Determine whether the current user has pulled the user_info information
          await store.dispatch('GetUserInfo')
          activeUser = store.getters.user
          activeRole = store.getters.roles
          if (!activeUser || !activeRole) {
            logMsg += '\t[LogOut]\t[next /]'
            await store.dispatch('LogOut')
            next({ path: '/' })
          }

          // note: roles must be a object array! such as:
          // [{id: '1', name: 'editor'}, {id: '2', name: 'developer'}]
          await store.dispatch('GenerateRoutes', store.getters.user)
          if (!store.getters.permissionRoutes) {
            logMsg += '\t[Redirect]\t[next /]'
            next({ path: '/' })
          }

          // Hack method to ensure that addRoutes is complete,
          // set the replace: true so the navigation will not leave a history record
          next({ ...to, replace: true })
        } else {
          logMsg += `\t[roles=${activeRole}]`
          // No need to dynamically change permissions can be directly next()
          // delete the following permission judgment ↓
          if (hasPermission(activeRole, to.meta.roles)) {
            logMsg += `\t[Permission=${to.meta.roles}]\t[next]`
            next()
          } else {
            logMsg += `\t[!Permission=${to.meta.roles}]\t[next /401]`
            next({ path: '/401', replace: true, query: { noGoBack: true } })
          }
        }

        // copiado de Merlin
        /// ////////////////////////////////////////////////////////////////
        // Add a request interceptor
        axios.interceptors.request.use(async (config) => {
          const Authorization = activeToken
          return { ...config, headers: { common: { Authorization } } }
        }, (error) => Promise.reject(error))
        // axios.defaults.headers.common.Authorization =
        // `Bearer ${JSON.parse(localStorage.getItem('fraven_user')).token}`;

        // Add a response interceptor
        axios.interceptors.response.use((response) => response, async (error) => {
          // Do something with response error
          if (error.response.status === 401) {
            await store.dispatch('LogOut')
            await router.push('/login')
          }
          return Promise.reject(error)
        })
        /// ////////////////////////////////////////////////////////////////
      }
    } else {
      logMsg += '\t[!token]'
      logMsg += '\t Path: ' + to.path
      if (includePath(whiteList, to)) {
        logMsg += '\t[whiteList]'
        next()
      } else {
        logMsg += '\t[!whiteList]'
        next(`/login?redirect=${to.path}`)
      }
    }
  } catch (err) {
    // console.warn(`[router.beforeEach]\t${to.path}: ${err}`)
  }
  // console.log(logMsg, to.path)
  NProgress.done()
})

router.afterEach(async () => {
  NProgress.done()
})
