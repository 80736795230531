export default {
  data () {
    return {
      defRules: {
        required: (value) => !!value || 'Requerido.',
        counter: (value) => value === null || value.length <= this.counter || 'Máximo de Caracteres.'
      }
    }
  },
  computed: {
    casRules () {
      const arr = this.rules
      if (this.required)
        arr.splice(0, 0, this.defRules.required)

      if (Number(this.counter) > 0)
        arr.splice(0, 0, this.defRules.counter)

      return arr
    }
  },
  props: {
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    counter: {
      type: [String, Number]
    }
  }
}
