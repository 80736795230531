import api from '../../api/ReportesApi'

/* eslint no-param-reassign: ["error", { "props": false }] */
export default {
  namespaced: true,
  state: {
  },
  getters: {},
  actions: {
    Execute (ctx, params) {
      const result = api.Execute(params)
      return result
    }
    // agregar({ commit }, item) {},
    // modificar({ commit }, item) {},
    // borrar({ commit }, item) {},
  },
  mutations: {
  }
}
